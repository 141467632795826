import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '13, 71, 161',
		'primary-dark': '0, 0, 81',
		'accent': '255, 214, 0',
		'accent-plus': '0, 0, 0',
	},
});
